@font-face {
  font-family: Parisienne;
  src: url("/assets/font/Parisienne/Parisienne-Regular.ttf");
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

#lesStratégiesDeGestionDuStress,
#lesEffetsDuStressSurLeCorpsEtLEsprit,
#lesOriginesDuStress,
#conclusion {
  scroll-margin-top: 60px;
}
#techniquesDeRelaxationEtDeMéditation,
#changerDePerspective,
#demanderDeLAide,
#adopterUneApprochePréventive {
  scroll-margin-top: 100px;
}

p,
li,
ul,
a,
figcaption,
summary,
label,
input,
span {
  font-family: Raleway, sans-serif;
}
h1,
h2,
h3 {
  font-family: Parisienne;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-color: orange;
  text-decoration-thickness: 2px;
  text-align: center;
}
summary {
  font-size: clamp(15px, 17px, 2vw);
}
p,
label,
input,
button,
ul {
  font-size: clamp(15px, 17px, 2vw);
}
h1 {
  font-size: clamp(36px, 48px, 5vw);
}
h2 {
  font-size: clamp(30px, 38px, 4vw);
}
h3 {
  font-size: clamp(26px, 32px, 4vw);
}
.slot-cell {
  flex: 1;
  text-align: center;
  opacity: 0;

  &:hover {
    background: rgba(0, 255, 0, 0.2);
    cursor: pointer;
    opacity: 1;
  }
}
button,
input[type="button"] {
  outline: none;
  box-shadow: none !important;
}
button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner {
  border: 0;
  box-shadow: none !important;
}

/*
  Accessibility (A11Y)
  Don't forget! User accessibility is important
*/
button:focus,
input[type="button"]:focus {
  border: none;
  outline: none;
  box-shadow: none !important;
}
.fc .fc-toolbar-title {
  font-size: 1.3em;
  text-decoration: none;
  font-family: "__Inter_d65c78", "__Inter_Fallback_d65c78";
}
.fc .fc-button {
  background-color: white !important;
  color: black;
}
.fc .fc-button:hover {
  background-color: aqua !important;
  color: black;
}
.fc .fc-button-primary:disabled {
  background-color: gray !important;
}
.fc .fc-button-primary:disabled:hover {
  color: white;
}
.fc .fc-bg-event {
  opacity: 0;
  text-align: center;
  padding: 8px 0;
}

.fc .fc-bg-event:hover,
.fc .fc-bg-event:hover * {
  opacity: 1;
  cursor: pointer;
}

details summary {
  padding: 20px 20px 30px 20px;
  cursor: pointer;
  font-weight: 700;
  margin-bottom: -10px; /* for more prominent move */
  transition: margin 450ms ease-out;
}
@media screen and (max-width: 500px) {
  details summary {
    padding: 15px 50px 50px 15px;
    margin-bottom: -35px;
  }
}

@media screen and (max-width: 1100px) {
  .fc .fc-toolbar {
    display: unset;
  }
  .fc-toolbar-title {
    margin-bottom: 10px !important;
  }
  .fc-toolbar-chunk {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .fc-toolbar-title {
    margin-bottom: 20px !important;
  }
}
.fc-timegrid-slot {
  cursor: pointer;
}
/* 
.fc-timegrid-slot:hover {
  background: black;
} */

details[open] summary {
  padding: 20px 20px 10px 20px;
  margin-bottom: 10px;
}

@media screen and (max-width: 500px) {
  details[open] summary {
    padding: 15px 50px 27px 15px;
    margin-bottom: -10px;
  }
}

details > summary::-webkit-details-marker,
details > summary::marker {
  display: none;
  content: "";
}

details summary:after {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  content: "▶";
}

details[open] summary:after {
  position: absolute;
  right: 30px;
  top: unset;
  transform: unset;
  content: "▼";
}

@media screen and (max-width: 769px) {
  .css-f2kq0q-MuiFormControl-root {
    min-width: unset;
  }
  .vertical-timeline-element-title {
    font-size: 16px;
  }
}

html,
body {
  width: 100vw;
  //overflow-x: hidden;
}

/* * {
  border: 1px solid red;
} */

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2 {
  text-align: center;
}

.css-1d1r5q-MuiFormHelperText-root {
  color: red !important;
}

.flash {
  &__modal {
    &__standard {
      border-radius: 10px;
      color: white;
      padding: 15px 55px;
      text-align: center;
      position: fixed;
      box-shadow: 4px 5px 15px 0px black;
      bottom: 0px;
      z-index: 1;
      left: 50%;
      width: 95%;
      transition: all 400ms;
      z-index: 999;
      &__img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
      }
      &__span {
        transform: translateY(-50%);
        position: absolute;
        right: 10px;
        top: 50%;
        font-size: 30px;
        color: white;
        padding: 0px 10px 5px 10px;
        border-radius: 50%;
        cursor: pointer;
        transition: all 400ms;
        &__error {
          &:hover {
            background-color: white;
            color: red;
          }
        }
        &__success {
          &:hover {
            background-color: white;
            color: green;
          }
        }
      }
    }
    &__error {
      background-color: red;
    }
    &__success {
      background-color: green;
    }
  }
}

@media screen and (max-width: 425px) {
  .flash {
    &__modal {
      &__standard {
        bottom: -10px;
        width: 98%;
      }
    }
  }
}

.fc-header-toolbar {
  display: inline-block;
  padding: 4px 4px;
}

.fc-scrollgrid-sync-table {
  width: 100%;
}

.fc-view {
  width: 100%;
  height: 100%;
  background-color: white;
}

.fc-h-event {
  background-color: violet;
  border-color: violet;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: violet;
}

.fc .fc-button {
  background-color: violet;
}

.fc .fc-button:hover {
  background-color: blue;
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: violet;
}

.fc .fc-button-primary:disabled {
  background-color: violet;
}
.fc-v-event {
  background-color: violet;
  border-color: violet;
}
